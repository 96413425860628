import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
// import Trend from 'react-trend'

import SeoHeader from '../components/SeoHeader'
import Hero from '../components/Hero'
import InnovationShowcase from '../components/InnovationShowcase'

export default function Innovation ({ data }) {
  return (
    <React.Fragment>
      <SeoHeader
        title='Innovation'
      />
      <InnovationShowcase
        image={data.imageThree}
      />
      <div className="breakup clearfix" />
      {/* <Hero
        mainTitle="Lab Test Data"
        imgSrc={data.imageTwo}
        subtitle="Shamal aims to enhance effectiveness by providing workers with a durable gear that gives a cooling effect for improved productivity."
        alignment="right"
      >
        <Trend
          smooth
          autoDraw
          autoDrawDuration={10000}
          autoDrawEasing="ease-out"
          data={[0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0]}
          gradient={['#000', '#fff100', '#FADB31']}
          radius={10}
          strokeWidth={2}
          strokeLinecap={'butt'}
        />
      </Hero>
      <div className="breakup clearfix" /> */}
      <Hero
        mainTitle="join the change today!"
        imgSrc={data.bookADemo}
        subtitle="Reach out to us to witness the product in action."
        // trailingText={[
        //   'Hear some of the success stories from ',
        //   <a
        //     href="/partners"
        //     key="partners"
        //     className="font-medium hoverShamal"
        //   >
        //     OUR PARTNERS
        //   </a>,
        //   '.'
        // ]}
        buttons={['demo']}
      />
      <div className="my-12" />
    </React.Fragment>
  )
}

Innovation.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    imageOne: file(relativePath: { eq: "img/1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "img/2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "img/3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bookADemo: file(relativePath: { eq: "img/_AU_0569.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
