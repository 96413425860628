import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Circle = styled.div`
  position: absolute;
  top: ${props => props.x}px;
  left: ${props => props.y}px;
  padding: 0.5rem;
  @media(min-width: 868px) {
    padding: 0.625rem;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 0.25rem solid #FADB31;
  background: transparent;
  transition: all 0.5s ease-out;
  transform: scale(${props => props.hover ? 1.7 : 1});
  z-index: 1;
`

const ContentBlock = styled.div`
  /* border: 0.25rem solid #fff; */
  position: absolute;
  text-align: left;
  max-width: 38%;
  top: ${props => props.x}px;
  left: ${props => props.y}px;
  transition: all 0.5s ease-out;
  transform: scale(${props => props.hover ? 1.1 : 1});
  background-color: ${props => props.hover ? '#00000033' : null};

`

const Title = styled.h3`
  color: #FADB31;
  font-size: 1.4rem;
  @media(max-width: 1100px) {
    display: none;
  }
`

const Subtitle = styled.p`
  color: #fff;
  padding-bottom: 0.7rem;
  @media(max-width: 1100px) {
    display: none;
  }
`

const BrandTitle = styled.h4`
  color: #FADB31;
  font-size: 1rem;
  @media(max-width: 1100px) {
    display: none;
  }
`

const BrandSubtitle = styled.p`
  color: #fff;
  /* font-size: 0.8rem; */
  @media(max-width: 1100px) {
    display: none;
  }
`

const BrandSubSubtitle = styled.p`
  color: #fff;
  font-family: 'FF DIN Pro Medium';
  /* font-size: 0.8rem; */
  @media(max-width: 1100px) {
    display: none;
  }
`

const StyledSVG = styled.svg`
  position: absolute;
  height: 4.25rem;
  width: 0.8rem;
  top: 1rem;
  left: -1rem;
  border-left: #FADB31 0.2rem solid;
  border-top: #FADB31 0.2rem solid;
  border-bottom: #FADB31 0.2rem solid;
  @media(max-width: 1100px) {
    display: none;
  }
`

export default function InnovationContent({ x, y, title, subtitle, contentX, contentY, brandTitle, brandSubtitle, brandSubSubtitle }) {
  const [hover, toggleHover] = React.useState(false)

  return (
    <div
      onMouseOver={() => toggleHover(!hover)}
      onMouseOut={() => toggleHover(!hover)}
    >
      <Circle x={x} y={y} hover={hover} />
      <ContentBlock x={contentX} y={contentY} hover={hover} >
        <StyledSVG width="500" height="500"><line x1="0" y1="0" x2="0" y2="50" stroke="#FADB31"/></StyledSVG>
        <Title className='font-medium'>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <BrandTitle className='font-medium'>{brandTitle}</BrandTitle>
        {
          brandSubSubtitle && (
            <BrandSubSubtitle>
              {brandSubSubtitle}
            </BrandSubSubtitle>
          )
        }
        <BrandSubtitle>{brandSubtitle}</BrandSubtitle>
      </ContentBlock>
    </div>
  )
}

InnovationContent.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  contentX: PropTypes.number,
  contentY: PropTypes.number,
  brandTitle: PropTypes.string,
  brandSubtitle: PropTypes.string,
  brandSubSubtitle: PropTypes.string
}
