import React, { Component } from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

import InnovationContent from './InnovationContent'

const brandList = ['tfs', 'ds', 'ors', 'hs', 'as']

export default class InnovationShowcase extends Component {
  constructor(props) {
    super(props)

    this.state = {
      brand: null,
      imgHeight: null,
      imgWidth: null,
      originalHeight: 6720,
      originalWidth: 8725,
      hover: false,

      tfs: {
        x: 3150,
        y: 3710,
        scaledX: null,
        scaledY: null,
        contentX: 3100,
        contentY: 400,
        scaledContentX: null,
        scaledContentY: null,
        title: 'Tight-Fix System',
        subtitle: 'Safety and functionality down to the last adjustable button',
        brandTitle: 'Fastening technology with High-Strength Buttons from YKK®',
        brandSubtitle: 'Tough High-Strength Buttons are specially designed for long life and to prevent thread breakage.'
      },
      ds: {
        x: 4150,
        y: 4700,
        scaledX: null,
        scaledY: null,
        contentX: 3850,
        contentY: 5250,
        scaledContentX: null,
        scaledContentY: null,
        title: 'Durassist System',
        subtitle: 'High abrasion resistance against regular friction and sharp objects',
        brandTitle: 'CORDURA® NYCO',
        brandSubSubtitle: 'Tactical, Tough, Comfort',
        brandSubtitle: 'Originally designed as a rugged, lightweight comfort solution for military combat uniforms,hardwearing CORDURA®NYCO fabric offers enhanced abrasion and tear resistance performance.'
      },
      ors: {
        x: 1800,
        y: 3900,
        scaledX: null,
        scaledY: null,
        contentX: 900,
        contentY: 400,
        scaledContentX: null,
        scaledContentY: null,
        title: 'Omni-Reflect System',
        subtitle: 'Offers high visibility for a safer work environment',
        brandTitle: 'High visibility technology by 3M™ Scotchlite™',
        brandSubtitle: '3M™ Scotchlite™ reflective material is composed of wide-angle, exposed retroreflective lenses that produce a shine or glow with the slightest light falling on it. The material provides conspicuity of the wearer around the worksite during daylight conditions and under illumination of headlights in the dark.'
      },
      hs: {
        x: 1900,
        y: 4465,
        scaledX: null,
        scaledY: null,
        contentX: 500,
        contentY: 5250,
        scaledContentX: null,
        scaledContentY: null,
        title: 'Hydropores System',
        subtitle: 'Hydrophobic properties that ensures high wicking of sweat',
        brandTitle: 'COOLMAX® PROfreshFX®',
        brandSubSubtitle: 'Cool, Dry and Fresh',
        brandSubtitle: 'COOLMAX® PROfreshFX® technology provides long-lasting freshness and confidence. Engineered to keep the wearer to stay cool, dry and fresh all day long effectively.'
      },
      as: {
        x: 2501,
        y: 4553,
        scaledX: null,
        scaledY: null,
        contentX: 2201,
        contentY: 5453,
        scaledContentX: null,
        scaledContentY: null,
        title: 'Airvent System',
        subtitle: 'Breathable mesh fabric that delivers natural ventilation',
        brandTitle: 'CORDURA® AFT',
        brandSubSubtitle: 'Breathable, Mesh Durability',
        brandSubtitle: 'Highly breathable knit constructions engineered for enhanced abrasion and snagging resistance.'
      }
    }
  }

  componentDidMount() {
    window.addEventListener(
      'resize', () => this.updateDimensions(),
      false
    )
  }

  componentWillUnmount() {
    window.addEventListener(
      'resize', () => this.updateDimensions(),
      false
    )
  }

  updateBrandCoordinates(originalHeight, originalWidth, imgHeight, imgWidth) {
    brandList.forEach(brand => {
      const stateBrand = this.state[brand]

      const scaledX = stateBrand.x / (originalHeight / imgHeight)
      const scaledY = stateBrand.y / (originalWidth / imgWidth)
      const scaledContentX = stateBrand.contentX / (originalHeight / imgHeight)
      const scaledContentY = stateBrand.contentY / (originalWidth / imgWidth)

      this.setState({
        [brand]: {
          ...stateBrand,
          scaledX,
          scaledY,
          scaledContentX,
          scaledContentY
        }
      })
    })
  }

  updateDimensions() {
    const imgComponent = document.getElementById('imgDiv')

    // this.setState({
    //   imgHeight: imgComponent.clientHeight,
    //   imgWidth: imgComponent.clientWidth
    // })

    // TODO: https://github.com/FezVrasta/react-resize-aware
    // https://github.com/souporserious/react-measure

    const { originalHeight, originalWidth } = this.state

    this.updateBrandCoordinates(originalHeight, originalWidth, imgComponent.clientHeight, imgComponent.clientWidth)
  }

  render() {
    const { image } = this.props
    const { tfs, ds, ors, hs, as, hover } = this.state

    const toggleHover = () => {
      this.setState({ hover: !hover })
    }

    const imgStyle = hover ? 'innovationImg greyscale' : 'innovationImg'

    return (
      <div className="w-full flex bg-black text-center overflow-hidden">
        <div
          id='imgDiv'
          className='z-0 relative w-full'
          onMouseOver={toggleHover}
          onMouseOut={toggleHover}
        >
          <Img
            fluid={image.childImageSharp.fluid}
            alt="showcase of our workwear"
            onLoad={() => this.updateDimensions()}
            className={imgStyle}
          />

          <InnovationContent
            title={tfs.title}
            subtitle={tfs.subtitle}
            x={tfs.scaledX}
            y={tfs.scaledY}
            contentX={tfs.scaledContentX}
            contentY={tfs.scaledContentY}
            brandTitle={tfs.brandTitle}
            brandSubtitle={tfs.brandSubtitle}
          />

          <InnovationContent
            title={ds.title}
            subtitle={ds.subtitle}
            x={ds.scaledX}
            y={ds.scaledY}
            contentX={ds.scaledContentX}
            contentY={ds.scaledContentY}
            brandTitle={ds.brandTitle}
            brandSubSubtitle={ds.brandSubSubtitle}
            brandSubtitle={ds.brandSubtitle}
          />

          <InnovationContent
            title={ors.title}
            subtitle={ors.subtitle}
            x={ors.scaledX}
            y={ors.scaledY}
            contentX={ors.scaledContentX}
            contentY={ors.scaledContentY}
            brandTitle={ors.brandTitle}
            brandSubtitle={ors.brandSubtitle}
          />

          <InnovationContent
            title={hs.title}
            subtitle={hs.subtitle}
            x={hs.scaledX}
            y={hs.scaledY}
            contentX={hs.scaledContentX}
            contentY={hs.scaledContentY}
            brandTitle={hs.brandTitle}
            brandSubtitle={hs.brandSubtitle}
            brandSubSubtitle={hs.brandSubSubtitle}
          />

          <InnovationContent
            title={as.title}
            subtitle={as.subtitle}
            x={as.scaledX}
            y={as.scaledY}
            contentX={as.scaledContentX}
            contentY={as.scaledContentY}
            brandTitle={as.brandTitle}
            brandSubtitle={as.brandSubtitle}
            brandSubSubtitle={as.brandSubSubtitle}
          />
        </div>
      </div>
    )
  }
}

InnovationShowcase.propTypes = {
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.object
    })
  })
}
